import React from 'react';

import './css/performanceMaintenanceMsg.css';

/**
 * This maintenance routine component is schedule automatically
 * weekly to show on every Friday
 */
const PerformanceMaintenanceMsg = props => {
  if (props.showMsg) {
    return (
      <div className="login-maintenance-msg-container">
        <label className="login-maintenance-msg-notice-label">
          {props.heading !== '' ? props.heading : null}
        </label>
        <span className="login-maintenance-msg">{props.msg}</span>
      </div>
    );
  } else {
    return null;
  }
};

export default PerformanceMaintenanceMsg;
