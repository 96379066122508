import { useState } from 'react';

/**
 * Takes useState and makes it behave similar to how setState does in a class component.
 *
 * @example:
 *
 * const [state, setState] = useSetState({
 *  hasFlag: false,
 *  count: 0,
 * });
 *
 * setState({ hasFlag: true });
 * // OR
 * setState((prevState) => ({count: prevState.count + 1}));
 */
export const useSetState = (initialState = {}) => {
  const [state, normalSetState] = useState(initialState);

  const setState = newState => {
    if (typeof newState === 'function') {
      normalSetState(prevState => ({
        ...prevState,
        ...newState(prevState),
      }));
    } else {
      normalSetState(prevState => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  return [state, setState];
};
