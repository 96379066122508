import React from 'react';
// External imports
import { Prompt, withRouter, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { Button, Dialog, Snackbar, MenuList, withStyles, Paper } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
// Internal imports
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import * as Global from '~/app/constants';
import UtilityAPI from '~/app/api/utilityAPI';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import AddNewReturn from '~/app/Pages/TaxReturns/components/AddNewReturn/AddNewReturn.jsx';
import RefundInformation from '#/Common/RefundInformation.jsx';
import CLOIdleTimer from '#/Common/CLOIdleTimer.jsx';
import CalcMutator from '~/app/Pages/RefundCalculator/components/CalcMutator.jsx';
import MessageDialog from '~/app/Pages/Returns/components/MessageDialog/MessageDialog.jsx';
import { crockfordBase32ToDecimal } from '~/app/Utility/general';
// Redux imports
import { connect } from 'react-redux';
import { store } from '~/app/redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import { actions as returnActions } from '~/app/redux/returnList/duck';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
// Styling imports
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/RefundCalculator/css/formViewerLite.js';
import '~/app/Pages/RefundCalculator/css/formViewerLite.css';

const mapDispatchToProps = {
  ...appActions,
  ...drilldownActions,
  ...returnActions,
  ...formViewerActions,
  ...returnProfileActions,
};

const mapStateToProps = state => {
  const currentView = state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1];
  return {
    activeAccessLevels: loginSelector.getActiveAccessLevels(state),
    drilldownHistory: state.drilldown.drilldownHistory,
    formList: state.returnProfile.formList,
    estimatorBlob: state.formViewer.estimatorState
      ? state.formViewer.estimatorState.blob
      : undefined,
    estimatorSSN: state.formViewer.estimatorState ? state.formViewer.estimatorState.ssn : undefined,
    currentView: currentView,
    loginID: currentView.loginID,
    officeProfile: state.officeProfile,
    messageDialogOpen: state.formViewer.messageDialogOpen,
    messageDialogInfo: state.formViewer.messageDialogInfo,
  };
};

const EXIT_TITLE = 'Close Refund Calculator';
const EXIT_BODY = 'Information will be lost. Are you sure you want to close the Refund Calculator?';

class FormViewerLite extends React.Component {
  constructor(props) {
    super(props);

    this.mutator = React.createRef();

    this.socketWorker = undefined;
    this.calcAPI = undefined;
    this.valueMap = {};
    this.colorMap = {};
    this.state = {
      body: '',
      activeForm: Global.FORM_NAMES.RFD_CALC_START,
      menuButtonsChoices: [],
      showExitDialog: false,
      errMsg: '',
      errorSnackbar: false,
      isAddNewReturnModalOpen: false,
      menuButtonOpen: false,
      // -1 is the entrypoint by convention, a new estimator rtnID will be returned in return_init
      issuedEstimatorReturnID: -1,
      results: '',
      estimatorBlob: '',
      willCreateReturn: false,
      hasConfirmedClose: false,
      routeRedirect: false,
      season: WebHelpers.getJWTPayload().season,
    };
  }

  getActiveLoginID = () => {
    const payload = WebHelpers.getJWTPayload();
    return payload.loginID;
  };

  DOM = () => {
    return this.mutator.current;
  };

  componentDidMount() {
    this.DOM().hideFormButton();
    // This prevents the back button going to previous page until user confirms to exit
    history.pushState(null, null, location.pathname);
  }

  componentWillUnmount = () => {
    this.props.onCloseEstimator();
    document.body.classList.remove('bodyStyleOverflowHidden');
  };

  doAlertJSON = message => {
    this.setState({
      errorSnackbar: true,
      errMsg: message.error,
    });
  };

  doAlert = message => {
    this.setState({
      errorSnackbar: true,
      errMsg: message,
    });
  };

  hideAlert = () => {
    this.setState({
      errorSnackbar: false,
      errMsg: '',
    });
  };

  getRefundOrBalDue = () => {
    let value = this.state.results.substring(this.state.results.indexOf('$') + 1);
    if (!value || value === '') {
      value = 0;
    } else if (this.state.results.includes('Due')) {
      value = `-${value}`; // insert negative symbol
    }
    return value;
  };

  handleMenuCommand = cmd => {
    switch (cmd) {
      case 'refundCalcCreate':
        this.DOM().returnSave([
          {
            stateName: 'FEDERAL',
            refundOrBalDue: this.getRefundOrBalDue(),
            season: 2019,
          },
        ]);
        break;
      case 'refundCalcExit':
        this.setState({
          showExitDialog: true,
        });
        break;
      default:
        console.log('Did not understand calcserver menu command');
        break;
    }
  };

  onSocketResponse = async evt => {
    // What if
    switch (evt.data) {
      case 'SOCKET_OPEN':
        this.DOM().returnInit(true);
        return;
      case 'CLOSE':
      case 'REFRESH':
      case 'CRITICAL':
        console.log(`${evt.data} received. Ignoring...`);
        return; // Do something if needed.
      default:
        break;
    }

    let response = JSON.parse(evt.data);
    switch (response.command) {
      case Global.CALC_RESPONSE_CODE.RETURN_INIT: {
        const returnInit = JSON.parse(UtilityAPI.trimNull(response.data));
        const rtnInitCalcResponse = JSON.parse(returnInit.calcResponse);

        // if the return ID from the cs response is a friendly ID, convert it to a system ID
        const rtnID = isNaN(parseInt(rtnInitCalcResponse.rtnId))
          ? crockfordBase32ToDecimal(rtnInitCalcResponse.rtnId, false)
          : parseInt(rtnInitCalcResponse.rtnId);

        this.setState(
          {
            issuedEstimatorReturnID: rtnID,
          },
          () => {
            this.DOM().switchReturnID(this.state.issuedEstimatorReturnID);
          },
        );
        this.colorMap = returnInit.colorMap;
        document.body.classList.add('bodyStyleOverflowHidden');
        // wait until return_init finishes to do form load, so we use the new returnID
        this.DOM().returnFormLoad(Global.FORM_NAMES.RFD_CALC_START, '', '', '');
        break;
      }
      case Global.CALC_RESPONSE_CODE.FORM_LOAD: {
        const formdata = JSON.parse(UtilityAPI.trimNull(response.formdata));
        let hasFormSwitch = false;

        if (formdata && formdata.frmSwitch) hasFormSwitch = true;

        if (hasFormSwitch) {
          // scroll to top when switching forms
          document.getElementById('formContainer').scrollTop = 0;
        }

        if (
          Object.prototype.hasOwnProperty.call(formdata, 'arg') &&
          Object.prototype.hasOwnProperty.call(formdata.arg, 'NavPanelItems')
        ) {
          // Possibly pass NavPanelItems with every form_load request?
          store.dispatch(returnProfileActions.setActiveReturnFormList(formdata.arg.NavPanelItems));
        }
        this.setState(
          {
            body: response.form,
            activeForm: hasFormSwitch ? formdata.frmSwitch : this.state.activeForm,
          },
          () => {
            this.DOM().bindFormEvents(this);
            this.DOM().hideFormButton();
            let args;
            if (Object.prototype.hasOwnProperty.call(formdata, 'arg')) {
              args = formdata.arg;
            } else {
              args = formdata;
            }
            this.DOM().UpdateForm(args);
          },
        );
        break;
      }
      case Global.CALC_RESPONSE_CODE.RETURN_BUTTONPRESS: {
        const data = JSON.parse(UtilityAPI.trimNull(response.data));
        const msg = data.arg.MSGBOX;
        if (!msg) {
          const cmd = data.arg.menuCmd;
          if (!cmd) {
            console.log('received messagebox but no message or command; tell luis!');
            return;
          }
          this.handleMenuCommand(cmd);
        } else {
          store.dispatch(formViewerActions.openMsgDialog(msg));
        }
        break;
      }
      case Global.CALC_RESPONSE_CODE.UPDATE_FORM: {
        const data = JSON.parse(UtilityAPI.trimNull(response.data));
        this.DOM().UpdateForm(data.arg);

        const navChildren = data.arg.NavPanelItems[0].Children;

        const navItem = navChildren.filter(item => item.var === 'ZZ9001')[0];
        if (navItem) {
          this.setState({
            results: navItem.desc,
          });
        }
        break;
      }
      case 'refundCalculator_createReturn': {
        const ret = JSON.parse(UtilityAPI.trimNull(response.data));
        XlinkAPI.getReturnProfileByReturnID(ret.returnID)
          .then(res => {
            const arr = [];
            if (res.data.attachments == null) {
              ret.attachments = [];
            } else {
              ret.attachments = res.data.attachments;
            }
            ret.refund_or_balance_due = res.data.refund_or_balance_due;
            ret.readyForReview = res.data.readyForReview;
            ret.year = this.state.season;
            ret.ssnein = res.data.ssnein;
            ret.firstName = res.data.first_name;
            ret.lastName = res.data.last_name;
            this.props.setActiveReturn(ret, arr);
            this.props.setClientData({
              first_name: ret.first_name,
              last_name: ret.last_name,
            });
            this.props.setInitialForm(Global.FORM_NAMES.CDS, '');
            this.props.openActiveReturn();
            this.setState({ routeRedirect: true });
          })
          .catch(error => {
            ErrorHelpers.handleError('Failed to open return', error);
          });
        break;
      }
      case 'return_save': {
        if (!Object.prototype.hasOwnProperty.call(this.props.activeAccessLevels, 'add_return')) {
          this.doAlert(
            'User does not have the ability to create a return. Please contact your administrator.',
            'Access Denied',
          );
          break;
        }
        const data = JSON.parse(UtilityAPI.trimNull(response.data));
        this.setState({
          isAddNewReturnModalOpen: true,
          estimatorBlob: data.arg.rtnBlob,
        });
        break;
      }
      case 'return_form_error':
        try {
          response = JSON.parse(UtilityAPI.trimNull(response.data));
          if (response.error && response.initialField) {
            this.DOM().onUpdateFormError(response);
            this.doAlertJSON(response);
          } else if (response.error) {
            this.doAlertJSON(response);
          }
        } catch (error) {
          ErrorHelpers.handleError('Unable to complete action', error);
        }
        break;
      case 'return_prompt_currentyear':
        break;
      case 'stop_spin':
        break;
      case 'start_spin':
        break;
      case 'error':
        console.log(JSON.stringify(response));
        break;
      case 'return_open_hyperlink':
        window.open(response.data);
        break;
      default:
        console.log(`No response case for command=${response.command}`);
    }
  };

  openButtonMenu = () => {
    this.setState({
      menuButtonOpen: true,
    });
  };

  closeButtonMenu = () => {
    this.setState({
      menuButtonOpen: false,
    });
  };

  onCloseAddNewReturn = () => {
    this.setState({
      isAddNewReturnModalOpen: false,
    });
  };

  ssnDupeCheck = async ssn => {
    const efinID = this.props.officeProfile?.efin_id;

    try {
      const res = await XlinkAPI.rfndCalcSSNDupeCheck(ssn, efinID);
      if (statusOK(res)) {
        return res.data;
      }
    } catch (err) {
      ErrorHelpers.handleError('Error Creating new return', err);
    }
  };

  onAddNewReturn = async (ssn, checkdupe = true) => {
    if (checkdupe) {
      const duplicateSsn = await this.ssnDupeCheck(ssn);
      if (duplicateSsn?.cyReturns) {
        store.dispatch(returnActions.setExistingSSNEIN(true, duplicateSsn.cyReturns));
        return;
      }
    }
    this.props.setEstimatorSSN(ssn);
    this.setState(
      {
        willCreateReturn: true,
      },
      () => {
        this.DOM().rfdcalcCreateNewReturn(
          this.state.issuedEstimatorReturnID,
          this.state.estimatorBlob,
          ssn,
          undefined,
        );
      },
    );
  };

  // TODO: Full sidebar for refund calculator
  generateMinimalSidebar() {
    return (
      <div>
        <RefundInformation refundAmount={this.getRefundOrBalDue()} refundInfo={'Federal Refund'} />
      </div>
    );
  }

  handleConfirmClose = () => {
    this.setState({ hasConfirmedClose: true }, () => {
      this.props.onCloseEstimator();
      this.props.history.push({
        pathname: '/dashboard',
      });
    });
  };

  handleBackButton = (location, action) => {
    if (action === 'POP') {
      history.pushState(null, null, location.pathname);
      this.setState({ showExitDialog: true });
    }
  };

  getBody() {
    const { classes } = this.props;
    return (
      <div className="body-content">
        <Prompt
          message={(location, action) => {
            this.handleBackButton(location, action);
            return this.state.hasConfirmedClose;
          }}
        />
        <MuiThemeProvider theme={appTheme}>
          <CLOIdleTimer forcingLogout={() => this.onIdleLogout()} />
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className="sidebar-form-nav">
              <div className="formList">
                <MenuList component="nav">{this.generateMinimalSidebar()}</MenuList>
              </div>
            </div>
            <div id="formContainer" className="formContainer">
              <div className="formViewerlite-exit-estimator-button">
                <Button
                  id="btnExitEstimator"
                  size="small"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showExitDialog: true,
                    })
                  }
                >
                  Exit
                </Button>
              </div>
              <span>
                <div
                  id="taxform"
                  dangerouslySetInnerHTML={{
                    __html: this.state.body,
                  }}
                />
                <CalcMutator
                  ref={this.mutator}
                  loginID={this.props.loginID}
                  returnID={this.state.issuedEstimatorReturnID}
                  onSocketResponse={e => this.onSocketResponse(e)}
                  estimatorBlob={this.props.estimatorBlob}
                  estimatorSSN={this.props.estimatorSSN}
                  callerCtx={this}
                />
              </span>
            </div>
            <SimpleDialog
              open={this.state.showExitDialog}
              onClose={() => {
                this.setState({
                  showExitDialog: false,
                });
              }}
              onConfirm={this.handleConfirmClose}
              dialogTitle={EXIT_TITLE}
              contentText={EXIT_BODY}
            />
            <Modal
              className={classes.addReturnModals}
              isOpen={this.state.isAddNewReturnModalOpen}
              onRequestClose={this.onCloseAddNewReturn}
              shouldCloseOnOverlayClick={false}
            >
              <Paper className={classes.addReturnPaper}>
                <AddNewReturn
                  loginID={this.props.currentView.loginID}
                  onAddNewReturn={this.onAddNewReturn}
                  onCloseAddNewReturn={this.onCloseAddNewReturn}
                  onW7Link={this.onW7Link}
                  isFormViewerLite={true}
                />
              </Paper>
            </Modal>
            <Dialog
              open={this.props.messageDialogOpen}
              disableBackdropClick={true}
              onClose={this.props.closeMsgDialog}
            >
              <MessageDialog
                messageDialogInfo={this.props.messageDialogInfo}
                handleCloseDialog={this.props.closeMsgDialog}
              />
            </Dialog>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.errorSnackbar}
              onClose={() => this.hideAlert()}
              autoHideDuration={3500}
              ContentProps={{
                'aria-describedby': 'error-msg',
                className: 'snackbar-error',
              }}
              message={<div id="error-msg">{this.state.errMsg}</div>}
            />
          </div>
        </MuiThemeProvider>
      </div>
    );
  }

  render() {
    if (this.state.routeRedirect) {
      return <Redirect to={'/tax-return'} />;
    }
    return this.getBody();
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FormViewerLite)),
);
