import React from 'react';
// Internal imports
import { PUBLIC_BUCKET, CUSTOM_KB_ACCOUNTS } from '~/app/constants.js';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import '~/app/Components/HeaderBar/components/HelpMenu/css/helpMenu.css';

const HelpMenu = props => {
  const { canCobrand } = useSelector(state => ({
    canCobrand: state.cobrand.canCobrand,
  }));

  const onClickRepublicBankDisclosure = () => {
    window.open(
      `${PUBLIC_BUCKET.baseUrl}${PUBLIC_BUCKET.docs}${PUBLIC_BUCKET.file.republicDisclosureConsent}`,
    );
  };

  const handleLinkClick = url => {
    window.open(url);
  };

  return (
    <div className="help-menu-block">
      <span>
        {!canCobrand && (
          <div className="div-style-bottom">
            Support
            <div
              className="link-text-top"
              onClick={() => handleLinkClick('http://help.remotetaxsupport.com')}
            >
              Remote Tax Support
            </div>
          </div>
        )}
        <div className="div-style-bottom help-menu-last-block">
          Resources
          <div className="link-text-top" onClick={() => handleLinkClick('https://www.irs.gov')}>
            irs.gov
          </div>
          {CUSTOM_KB_ACCOUNTS.includes(props?.account_code) ? null : (
            <div
              className="link-text-top"
              onClick={() => handleLinkClick('https://kb.crosslinktax.com')}
            >
              Knowledge Base
            </div>
          )}
          <div
            className="link-text-top"
            onClick={() => handleLinkClick('https://thetaxbook.net/user/login')}
          >
            The TaxBook
          </div>
          <div
            className="link-text-top"
            onClick={() => handleLinkClick('https://www.irs.gov/refunds')}
          >
            Where&apos;s My Refund
          </div>
          <div
            className="link-text-top"
            onClick={() => handleLinkClick('https://www.irs.gov/filing/wheres-my-amended-return')}
          >
            Where&apos;s My Amended Return
          </div>
          {props.hasRepublicBankApproval && (
            <div className="link-text" onClick={onClickRepublicBankDisclosure}>
              Republic Bank&apos;s Disclosure and Consent
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

export default HelpMenu;
