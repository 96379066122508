import React, { Component } from 'react';
import Modal from 'react-modal';
// Create new Help Menu React Component
import SelectOffice from '#/Auth/SelectOffice.jsx';
import { connect } from 'react-redux';
import Settings from '~/app/Pages/Setup/Settings.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import * as Global from '~/app/constants.js';
import { actions as appActions } from './redux/modules/app';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import { actions as returnListActions } from './redux/returnList/duck';
import { actions as overviewActions } from './redux/modules/overview';
import { actions as widgetDashBoardActions } from '~/app/redux/widgets/duck';
import { actions as searchResultsActions } from '~/app/redux/search/duck';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
import { selectors as returnProfileSelectors } from '~/app/redux/returnProfile/selectors';
import WebHelpers, { handleSuddenCloseWindow } from '~/app/webHelpers.js';
import AuthAPI from '~/app/api/authAPI.js';
import UtilityAPI from '~/app/api/utilityAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { mainAppMapStateToPropsConfig } from '~/app/DataModel/mainApp.js';

const mapStateToProps = state => mainAppMapStateToPropsConfig(state, returnProfileSelectors);

const mapDispatchToProps = {
  ...appActions,
  ...returnListActions,
  ...formViewerActions,
  ...returnProfileActions,
  ...overviewActions,
  ...widgetDashBoardActions,
  ...searchResultsActions,
  ...setupPageActions,
  ...loginSetupActions,
  ...drilldownActions,
  ...notificationActions,
  ...cobrandActions,
};

Modal.setAppElement('#root');

/**
 * This component will eventually be deprecated once we move all pages and logic
 * out to dedicated directories and files. Once everything is moved out of this file
 * it will be deleted.
 *
 * NOTE: Adding additional logic to this file should be kept to a minimum and only
 * added to if absolutely neccessary.
 */
class AppBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatModalDialog: false,
    };
  }

  componentDidMount() {
    this.disableZoom();

    // this.props.setNavigation(this.props.navigation);
    window.addEventListener('popstate', () => {
      try {
        if (history.state.drilledDown === true) {
          this.props.loadPrevOverview(history.state.idx);
          history.back(-1);
          this.props.fetchLogo(true);
        }
      } catch (e) {
        console.info('Could not read history state');
      }
    });
  }

  componentWillUnmount() {
    window.addEventListener('popstate', () => null);
    window.removeEventListener('beforeunload', handleSuddenCloseWindow);
    window.removeEventListener('wheel', () => null);
    window.removeEventListener('keydown', () => null);
  }

  /**
   * We only want to start a LogRocket session only if it's in prod and
   * user logging in is an actual customer.
   */
  startLogRocketLogging = () => {
    const payload = WebHelpers.getJWTPayload();

    if (ENVIRONMENT !== 'production') {
      return;
    }

    if (payload) {
      if (
        payload.hierarchy_type_id &&
        payload.hierarchy_type_id > 1 &&
        payload.hierarchy_type_id < 50
      ) {
        this.startLogRocketSession();
      }
    } else {
      this.startLogRocketSession();
    }
  };

  /**
   * Disable Zoom in CLO.
   */
  disableZoom = () => {
    // Disable Ctrl + scroll zooming.
    document.body.addEventListener(
      'wheel',
      e => {
        if (e.ctrlKey) {
          e.preventDefault();
          // Scroll up
          if (e.deltaY < 0) {
            return false;
          }
          // Scroll down
          if (e.deltaY > 0) {
            return false;
          }
        }
      },
      { passive: false },
    );

    // Disable Ctrl- and Ctrl+ zooming.
    document.body.addEventListener(
      'keydown',
      e => {
        if (
          e.ctrlKey === true &&
          (e.which === '61' ||
            e.which === '107' ||
            e.which === '173' ||
            e.which === '109' ||
            e.which === '187' ||
            e.which === '189')
        ) {
          e.preventDefault();
        }
      },
      { passive: false },
    );
  };

  onClickContinueFromPickOffice = async (efin_id = 0, season) => {
    try {
      await AuthAPI.selectAnOfficeYear(efin_id, season);
      const basicInfo = await UtilityAPI.getBasicOfficeInfo(this.props.drilldownHistory);
      if (typeof basicInfo !== 'undefined') {
        this.props.setLoggedInBasicInfo(basicInfo);
      }
      this.props.resetDrilldown();
      // all good, try finalizeLogin again
      this.props.onFinalizeLogin();
    } catch (error) {
      ErrorHelpers.handleError('Error selecting office', error);
    }
  };

  disableBody = target => disableBodyScroll(target);

  enableBody = target => enableBodyScroll(target);

  render() {
    const payload = WebHelpers.getJWTPayload();
    let settingsLoginID = '';
    if (this.props.drilldownHistory !== undefined && this.props.drilldownHistory.length > 1) {
      settingsLoginID = this.props.drilldownHistory[this.props.drilldownHistory.length - 2].loginID;
    } else if (typeof payload !== 'undefined') {
      settingsLoginID = payload.login_id;
    }

    if (this.props.authStage === Global.AUTH_STAGE.AWAIT_PICK_OFFICE) {
      return (
        <div className="body-content">
          <MuiThemeProvider theme={appTheme}>
            <Modal style={styles.switchModal} isOpen={true}>
              <SelectOffice
                onClickContinue={(efinID, season) =>
                  this.onClickContinueFromPickOffice(efinID, season)
                }
              />
            </Modal>
          </MuiThemeProvider>
        </div>
      );
    }

    if (this.props.navigation === 'database') {
      return (
        <div className="body-content">
          <MuiThemeProvider theme={appTheme}>
            <Settings
              onExitProfile={() => this.props.toggleSetupAccount()}
              loginID={settingsLoginID}
            />
          </MuiThemeProvider>
        </div>
      );
    }

    return (
      <div className="body-content">
        <MuiThemeProvider theme={appTheme}>
          <SimpleDialog
            open={this.props.alert.show}
            onConfirm={() => this.props.hideError()}
            dialogTitle={this.props.alert.title}
            contentText={this.props.alert.body}
            contentTextTwo={this.props.alert.bodyTwo}
            confirmText="OK"
            styled={true}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

// position the modals
const styles = {
  login: {
    content: {
      top: '25%',
      left: '40%',
      right: 'auto',
      bottom: 'auto',
    },
  },
  get pickoffice() {
    return {
      content: {
        ...this.login.content,
        top: '18%',
        left: '36%',
      },
    };
  },

  switchModal: {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: '500px',
      width: '618px',
      padding: '0',
      boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    },
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBody));
