import React, { useEffect } from 'react';
// Internal imports
import Checkmark from '~/images/icons/icons8-checkmark-48.png';
import { useSetState } from '~/app/Utility/customHooks.js';
import ReturnFormList from './ReturnFormList.jsx';
import RefundDisplay from './RefundDisplay.jsx';
// External imports
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, MenuList, Typography, Divider, Collapse, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import '~/app/Pages/WizardMode/components/SideBarForms/css/wizardSideBarForms.css';
import { styles } from '~/app/Pages/WizardMode/components/SideBarForms/css/wizardSidebarForms.js';



/**
 * Handles displaying which step in the Wizard Mode form process the user is currently on.
 * It also displays whether or not a form has been validated by using icon logic ui
 *
 * @component SideBarForms
 * @category WizardMode
 */
const WizardSideBarForms = props => {
  const { classes } = props;
  const { wizardCompleted, wizardFormStatus } = useSelector(state => ({
    wizardCompleted: state.returnProfile.wizardCompleted,
    wizardFormStatus: state.wizardEstimator.wizardFormStatus,
  }));
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useSetState({
    steps: [
      {
        step: 0,
        type: 'Taxpayer Info',
        valid: wizardFormStatus.taxpayerCompleted,
      },
      {
        step: 1,
        type: 'Contact Information',
        valid: wizardFormStatus.contactCompleted,
      },
      {
        step: 2,
        type: 'Children & Dependents',
        valid: wizardFormStatus.childrenCompleted,
      },
      {
        step: 3,
        type: 'Income',
        valid: wizardFormStatus.incomeCompleted,
      },
      {
        step: 4,
        type: 'Deductions',
        valid: wizardFormStatus.deductionsCompleted,
      },
      {
        step: 5,
        type: 'Credits',
        valid: wizardFormStatus.creditsCompleted,
      },
      {
        step: 6,
        type: 'State Income Tax',
        valid: wizardFormStatus.stateCompleted,
      },
      {
        step: 7,
        type: 'Misc Forms',
        valid: wizardFormStatus.miscellaneousCompleted,
      },
      {
        step: 8,
        type: 'Results',
        valid: wizardCompleted,
      },
    ],
    menuOpen: true,
  });

  useEffect(() => {
    setState({
      steps: [
        {
          step: 0,
          type: 'Taxpayer Info',
          valid: wizardFormStatus.taxpayerCompleted,
        },
        {
          step: 1,
          type: 'Contact Information',
          valid: wizardFormStatus.contactCompleted,
        },
        {
          step: 2,
          type: 'Children & Dependents',
          valid: wizardFormStatus.childrenCompleted,
        },
        {
          step: 3,
          type: 'Income',
          valid: wizardFormStatus.incomeCompleted,
        },
        {
          step: 4,
          type: 'Deductions',
          valid: wizardFormStatus.deductionsCompleted,
        },
        {
          step: 5,
          type: 'Credits',
          valid: wizardFormStatus.creditsCompleted,
        },
        {
          step: 6,
          type: 'State Income Tax',
          valid: wizardFormStatus.stateCompleted,
        },
        {
          step: 7,
          type: 'Misc Forms',
          valid: wizardFormStatus.miscellaneousCompleted,
        },
        {
          step: 8,
          type: 'Results',
          valid: wizardCompleted,
        },
      ],
    });
  }, [wizardFormStatus, wizardCompleted]);

  /**
   * Handles generating the section steps UI for the sidebar
   *
   * @returns {jsx} section steps UI
   */
  const displaySteps = () => {
    const returnInfo = state.steps.map(item => {
      return (
        <MenuItem
          className={
            props.wizardCurrentStep === item.step
              ? classes.selectedMenuItemStyle
              : classes.menuItemStyle
          }
          key={(`returninfo-`, item.step)}
          value={item.step}
          onClick={() => props.handleStep(item.step)}
        >
          {item.valid ? (
            // Check mark - validated
            <img
              title="Validated"
              id="subFormTabIcon"
              className="wizard-menulist-menuitem-icon"
              src={Checkmark}
            />
          ) : (
            // Red circle - not validated
            // unsure if we want to eventually reimplement, keeping
            // <span
            //   title="Not Validated"
            //   className="wizard-menulist-menuitem-dot"
            //   id="subFormTabIcon"
            // ></span>
            <img
              title="Validated"
              id="subFormTabIcon"
              className="wizard-menulist-menuitem-icon-hidden"
              src={Checkmark}
            />
          )}
          <Typography
            className={
              props.wizardCurrentStep === item.step
                ? classes.selectedMenuItemText
                : classes.menuItemText
            }
          >
            {item.type}
          </Typography>
        </MenuItem>
      );
    });

    return returnInfo;
  };

  const onHeaderClick = () => {
    setState({
      menuOpen: !state.menuOpen,
    });
  };

  return (
    <div className="wizard-side-bar-forms-content">
      {wizardCompleted ? <RefundDisplay /> : null}
      <div className="wizard-form-nav-container">
        <div id="sidebar-form-list" className="wizard-sidebar-form-nav">
          <div className="formList">
            <h4 className="wizard-sidebar-form-nav-header-text" onClick={() => onHeaderClick()}>
              {state.menuOpen ? (
                <ExpandLess className={classes.expand} />
              ) : (
                <ExpandMore className={classes.expand} />
              )}
              Guided Estimator
            </h4>
            <Collapse in={state.menuOpen} timeout="auto" unmountOnExit>
              <MenuList component="nav">{displaySteps()}</MenuList>
            </Collapse>
            {/* <MenuList component="nav">{displaySteps()}</MenuList> */}
            <Divider />
            {/* Display the forms that are attached to the return on sidebar */}
            {wizardCompleted ? (
              <ReturnFormList
                handleStep={props.handleStep}
                calcAPI={props.formViewerCalcAPI}
                verifyReturn={props.verifyReturn}
                toggleDeleteDialog={props.toggleDeleteDialog}
                openAddFormList={props.openAddFormList}
                wizardCurrentStep={props.wizardCurrentStep}
                getLockedStatus={props.getLockedStatus}
                assetMode={props.assetMode}
              />
            ) : null}
          </div>
        </div>
      </div>
      {wizardCompleted ? (
        <div className="wizard-add-a-form-btn-container">
          <Button
            id="btnAddFormSidebar"
            color="primary"
            size="small"
            className="wizard-add-form-btn"
            onClick={() => {
              if (!props.getLockedStatus()) {
                props.openAddFormList(null, null);
              }
            }}
            disabled={props.assetMode || props.getLockedStatus()}
          >
            Add a Form
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(WizardSideBarForms);
